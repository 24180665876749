<template>
  <v-progress-linear
    v-if="_showProgress"
    v-model="percentage"
    :color="color"
    :height="smartProgress.height"
    absolute
    bottom
  />
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      timer: 0,
      increment: 0,
      percentage: 0,
    };
  },

  props: {
    smartProgress: {
      type: Object,
      default: () => ({
        enabled: false,
        color: "primary",
        height: 8,
      }),
    },

    totalTime: {
      type: Number,
      default: 0,
    },

    state: {
      type: String,
      default: "ended",
    },

    color: {
      type: String,
      default: "primary",
    },
  },

  computed: {
    _showProgress() {
      const states = ["unstarted", "ended", "paused"];

      return this.smartProgress.enabled && !states.includes(this.state);
    },
  },

  methods: {
    handleTimeline(currentTime) {
      if (currentTime) this.timer = currentTime / 1000;

      this.interval = setInterval(() => {
        if (this.timer >= this.totalTime) {
          this.timer = this.totalTime;
          return this.clearInterval();
        }

        const totalTime = this.totalTime / 1000;

        const difference = totalTime - this.timer;
        this.increment = difference * 0.005;

        this.timer = this.timer + this.increment;
        this.percentage = (this.timer * 100) / totalTime;
      }, 1000);
    },

    clearInterval(reset) {
      if (this.interval) clearInterval(this.interval);
      if (reset) this.timer = 0;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
