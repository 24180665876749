import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Player from "../views/player/Player.vue";
import NotFound from "../views/notFound/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/player",
  },
  {
    path: "/player",
    name: "Player",
    component: Player,
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/player",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
