var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"player",staticClass:"player",style:({
    ...(_vm.settings.border.show && {
      'border-width': _vm.settings.border.size + 'px',
      'border-color': _vm.settings.border.color,
      'border-radius': _vm.settings.border.rounded ? '0.5rem' : '0rem',
      'border-style': 'solid',
    }),
  }),attrs:{"id":"player"}})
}
var staticRenderFns = []

export { render, staticRenderFns }