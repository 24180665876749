<template>
  <v-overlay
    :value="_showOverlay"
    :absolute="true"
    color="transparent"
    z-index="9999"
  >
    <div
      class="d-flex fill-height flex-column pointer"
      :class="{
        'disabled-click':
          !settings.reproductionButton && state === 'playing' && started,
      }"
      @click="handleReproduction()"
    >
      <v-expand-transition mode="out-in">
        <v-sheet
          v-if="showStripes && enabledStripes"
          height="3.75rem"
          class="glass-container stripe stripe-top"
          key="element"
        >
          <div class="glass-content"></div>
        </v-sheet>
      </v-expand-transition>

      <v-img
        v-if="_showContent"
        class="thumbnail"
        :src="_thumbnail"
        :class="{ neutral: !autoPlayed }"
        :style="{
          ...(settings.border.show && {
            'border-width': settings.border.size + 'px',
            'border-color': settings.border.color,
            'border-radius': settings.border.rounded ? '0.5rem' : '0rem',
            'border-style': 'solid',
          }),
        }"
      >
        <div class="d-flex fill-height align-center justify-center content">
          <v-progress-circular
            v-if="loading"
            color="white"
            :size="64"
            :width="6"
            indeterminate
          />

          <v-card
            v-else
            :color="_cardColor + 'E6'"
            class="pa-3 pa-sm-6 pa-md-10 rounded-lg card"
            max-width="80vw"
            flat
          >
            <section class="d-flex align-center flex-column">
              <!-- resume play title -->
              <div
                v-if="_resumeControls && !started && resumePlay.title"
                class="mb-4 text-body-2 text-sm-h6 text-md-h5 font-weight-regular text-center"
              >
                <span :style="`color: ${resumePlay.textColor}`">
                  {{ resumePlay.title }}
                </span>
              </div>

              <!-- auto play title -->
              <div
                v-if="_autoPlayControls"
                class="mb-4 text-body-2 text-sm-h6 text-md-h5 font-weight-regular text-center"
              >
                <span :style="`color: ${autoPlay.textColor}`">
                  {{ autoPlay.title }}
                </span>
              </div>

              <!-- option when finish video -->
              <v-btn
                v-if="state === 'ended'"
                :x-large="$vuetify.breakpoint.mdAndUp"
                :small="$vuetify.breakpoint.xsOnly"
                icon
                @click.stop.prevent="handleReproduction()"
              >
                <v-icon
                  :color="_autoPlayControls ? autoPlay.textColor : 'white'"
                  :x-large="$vuetify.breakpoint.mdAndUp"
                  :large="$vuetify.breakpoint.smAndDown"
                >
                  mdi-restart
                </v-icon>
              </v-btn>

              <!-- resume play control buttons -->
              <div
                v-else-if="_resumeControls"
                class="d-flex flex-wrap justify-center"
              >
                <v-btn
                  v-if="!started"
                  class="mr-sm-1 mb-2 mb-sm-0"
                  :color="resumePlay.textColor"
                  :x-small="$vuetify.breakpoint.xsOnly"
                  text
                  @click.stop.prevent="restart()"
                >
                  <v-icon class="mr-2"> mdi-restart </v-icon>

                  <div>{{ resumePlay.restartLabel }}</div>
                </v-btn>

                <v-btn
                  class="ml-sm-1"
                  :color="resumePlay.textColor"
                  :x-small="$vuetify.breakpoint.xsOnly"
                  text
                  @click.stop.prevent="handleReproduction()"
                >
                  <v-icon class="mr-2"> mdi-play </v-icon>

                  <div>{{ resumePlay.continueLabel }}</div>
                </v-btn>
              </div>

              <!-- default button play -->
              <v-btn
                v-else
                :x-large="$vuetify.breakpoint.mdAndUp"
                :small="$vuetify.breakpoint.xsOnly"
                icon
                @click.stop.prevent="handleReproduction()"
              >
                <v-img
                  v-if="_autoPlayControls"
                  :src="require('@/assets/gif-muted.gif')"
                  :max-height="$vuetify.breakpoint.mdAndUp ? '6rem' : '3rem'"
                  contain
                />

                <v-icon
                  v-else
                  color="white"
                  :x-large="$vuetify.breakpoint.mdAndUp"
                  :large="$vuetify.breakpoint.smAndDown"
                >
                  mdi-play
                </v-icon>
              </v-btn>

              <!-- auto play subtitle -->
              <div
                v-if="_autoPlayControls"
                class="mt-4 text-center ttext-caption text-sm-body-2 text-md-body-1 font-weight-regular"
              >
                <span :style="`color: ${autoPlay.textColor}`">
                  {{ autoPlay.subtitle }}
                </span>
              </div>
            </section>
          </v-card>
        </div>
      </v-img>

      <v-expand-transition mode="out-in">
        <div
          v-if="showStripes && !settings.progressBar"
          class="d-flex align-end justify-end stripe stripe-bottom pb-3 pr-4"
          key="element"
        >
          <v-card
            class="rounded-lg px-4 py-2"
            href="https://myvsl.com.br/"
            target="_blank"
            color="neutral"
            flat
          >
            <v-img
              :src="require('@/assets/logo-white.svg')"
              max-width="5rem"
              contain
            />
          </v-card>
        </div>
      </v-expand-transition>
    </div>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      showStripes: true,
      stripesTimeout: 3600,
      enabledStripes: false,
    };
  },

  props: {
    state: {
      type: String,
      default: "",
    },

    thumbnails: {
      type: Object,
      default: () => {},
    },

    autoPlay: {
      type: Object,
      default: () => {},
    },

    resumePlay: {
      type: Object,
      default: () => {},
    },

    settings: {
      type: Object,
      required: true,
    },

    restartOption: {
      type: Boolean,
      default: false,
    },

    autoPlayed: {
      type: Boolean,
      default: false,
    },

    resumed: {
      type: Boolean,
      default: false,
    },

    started: {
      type: Boolean,
      default: false,
    },

    haveControl: {
      type: Boolean,
      default: true,
    },

    showControls: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    autoPlayed(val) {
      if (val && this.autoPlay) {
        this.showStripes = true;

        setTimeout(() => {
          this.showStripes = false;
        }, this.stripesTimeout);
      }
    },

    started(val) {
      if (val) {
        this.showStripes = true;

        setTimeout(() => {
          this.showStripes = false;
        }, this.stripesTimeout);
      }
    },
  },

  computed: {
    _thumbnail() {
      if (
        this.autoPlay.enabled &&
        this.autoPlay.gifURL &&
        !this.autoPlayed &&
        !this.started
      )
        return this.autoPlay.gifURL;

      if (Object.keys(this.thumbnails).length === 0)
        return require("@/assets/default-thumb.png");

      if (
        this.state === "paused" &&
        this.thumbnails.holdThumb &&
        this.thumbnails.holdThumb.length
      )
        return this.thumbnails.holdThumb[this.thumbnails.holdThumb.length - 1];

      if (this.state === "ended" && this.thumbnails.finalThumb)
        return this.thumbnails.finalThumb;

      if (this.thumbnails.defaultThumb) return this.thumbnails.defaultThumb;

      return require("@/assets/default-thumb.gif");
    },

    _resumeControls() {
      return this.resumePlay.enabled && this.resumed;
    },

    _autoPlayControls() {
      return this.autoPlay.enabled && !this._resumeControls && !this.started;
    },

    _cardColor() {
      if (this.state === "ended") return "transparent";

      if (this._resumeControls) return this.resumePlay.cardColor;
      else if (this._autoPlayControls) return this.autoPlay.cardColor;

      return "transparent";
    },

    _showOverlay() {
      const states = ["unstarted", "ended", "paused"];

      return (
        states.includes(this.state) ||
        this.autoPlayed ||
        !this.started ||
        this.showStripes
      );
    },

    _showContent() {
      const states = ["unstarted", "ended", "paused"];

      return states.includes(this.state) || this.autoPlayed;
    },
  },

  methods: {
    handleReproduction() {
      this.$emit("change:reproduction");
    },

    restart() {
      this.$emit("restart");
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
