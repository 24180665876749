<template>
  <v-container justify-center align-center fill-height>
    <div class="d-flex fill-width flex-column justify-center align-center">
      <span class="text-h4 mb-6">
        {{ $t("notFound") }}
      </span>

      <v-row justify="center">
        <v-col cols="12" md="10" xl="8">
          <v-img :src="require('@/assets/logo-white.svg')" contain />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style src="./style.scss" lang="scss" scoped />
