import axios from "axios";

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 16000,
  withCredentials: true,
  headers: { secret: process.env.VUE_APP_API_SECRET },
});

const api = async () => {
  // interceptor
  http.interceptors.response.use(
    (res) => Promise.resolve(res),
    (err) => Promise.reject(err.request)
  );

  return http;
};

export async function request(data) {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return (await api()).post("", formData);
}

export default api;
